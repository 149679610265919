$(function() {
  $('select.application').selectric({
    arrowButtonMarkup: '<b class="button" style="background-image: url(\'/img/down-arrow.png\')"></b>',
  });

  });
$('.hamburger').click(function() {
  $('.menu-list').toggleClass('open');
  $(this).toggleClass('is-active');
  $('.sub-menu').removeClass('open');
  $('body').toggleClass('fixed');
});

$('.sub-container').click(function() {
  $(this).find('.sub-menu').addClass('open');
  $(this).siblings('.sub-container').find('.sub-menu').removeClass('open');
});

$('.feature').mouseenter(function() {
  $(this).addClass('active');
});
$('.feature').mouseleave(function() {
  $(this).removeClass('active');
});

var totalSlides = $('.newsroom-slider').find('.text-inner').length - 1;
var currentIndex = 0;
var currentFilter = null;

function newsSliderFilter(target) {
  var $this = $(target);

  $('.newsroom-slider').find('.cat').removeClass('active');
  $this.addClass('active');

  currentFilter = $this.attr('href');

  var text = $('.newsroom-slider').find('.text-inner.'+currentFilter).first();

  totalSlides = $('.newsroom-slider').find('.text-inner.'+currentFilter).length - 1;

  if(totalSlides == 0) toggleNewsSliderButtons(false);
  else toggleNewsSliderButtons(true);

  currentIndex = text.index();

  changeNewsSlide(currentIndex, currentFilter);
}

function newsSliderFilterReset(target) {
  $('.newsroom-slider').find('.cat').removeClass('active');
  toggleNewsSliderButtons(true);
  totalSlides = $('.newsroom-slider').find('.text-inner').length - 1;
  currentIndex = 0;
  currentFilter = null;
  if(totalSlides == 0) toggleNewsSliderButtons(false);
  else toggleNewsSliderButtons(true);
  changeNewsSlide(currentIndex, currentFilter);
}

function newsSliderNext() {

  if(currentIndex < totalSlides) {
    currentIndex ++;
  } else {
    currentIndex = 0;
  }

  changeNewsSlide(currentIndex, currentFilter);
}

function newsSliderPrev() {

    if(currentIndex > 0) {
    currentIndex --;
  } else {
    currentIndex = totalSlides;
  }

  changeNewsSlide(currentIndex, currentFilter);
}

function changeNewsSlide(currentIndex, currentFilter) {
  currentIndex = totalSlides == 0 ? 0 : currentIndex;
  if(currentFilter) {
    var text = $('.newsroom-slider').find('.text-inner.'+currentFilter).eq(currentIndex);
    var img = $('.newsroom-slider').find('.img.'+currentFilter).eq(currentIndex);
  } else {
    var text = $('.newsroom-slider').find('.text-inner').eq(currentIndex);
    var img = $('.newsroom-slider').find('.img').eq(currentIndex);
  }
  text.parent().children().removeClass('active');
  text.addClass('active');

  img.parent().children().removeClass('active');
  img.addClass('active');
}

function toggleNewsSliderButtons(visible) {
  if(visible) {
    $('.newsroom-slider').find('button.prev').show();
    $('.newsroom-slider').find('button.next').show();
  } else {
    $('.newsroom-slider').find('button.prev').hide();
    $('.newsroom-slider').find('button.next').hide();
  }
}

function nrNext() {
  var text = $('.newsroom-landing-hero').find('.nr-story.active');
  var textWrapper = $('.newsroom-landing-hero').find('.text');
  text.removeClass('active');
  if (text.is(':last-of-type')) {
    $('.newsroom-landing-hero').find('.text-inner:first-of-type').addClass('active');
  } else {
    text.next().addClass('active');
  }


    var img = $('.newsroom-landing-hero').find('.img.active');
  img.removeClass('active');
  if (img.is(':last-of-type')) {
    $('.newsroom-landing-hero').find('.img:first-of-type').addClass('active');
  } else {
    img.next().addClass('active');
  }
}

function nrPrev() {
  var text = $('.newsroom-landing-hero').find('.text-inner.active');
  var textWrapper = $('.newsroom-landing-hero').find('.text-inner');
  text.removeClass('active');
  if (text.index() == 0) {
    $('.newsroom-landing-hero').find('.text-inner:last-of-type').addClass('active');
  } else {
    text.prev().addClass('active');
  }


    var img = $('.newsroom-landing-hero').find('.img.active');
  img.removeClass('active');
  if (img.is(':first-of-type')) {
    $('.newsroom-landing-hero').find('.img:last-of-type').addClass('active');
  } else {
    img.prev().addClass('active');
  }
}

$('.nav-toggle').click(function() {
  var menu = $('.newsroom-header').find('.menu');

  menu.toggleClass('open');
});
$('.testNext').click(function() {
  var text = $(this).parents('.testimonials').find('.text-inner.active');
  var textWrapper = $('testimonials').find('.text');
  text.removeClass('active');
  if (text.is(':last-of-type')) {
    $('.testimonials').find('.text-inner:first-of-type').addClass('active');
  } else {
    text.next().addClass('active');
  }


    var img = $(this).parents('.testimonials').find('.img.active');
  img.removeClass('active');
  if (img.is(':last-of-type')) {
    $('.testimonials').find('.img:first-of-type').addClass('active');
  } else {
    img.next().addClass('active');
  }
});
$('.testPrev').click(function() {
  var text = $(this).parents('.testimonials').find('.text-inner.active');
  var textWrapper = $('testimonials').find('.text-inner');
  text.removeClass('active');
  if (text.index() == 0) {
    $('.testimonials').find('.text-inner:last-of-type').addClass('active');
  } else {
    text.prev().addClass('active');
  }


    var img = $(this).parents('.testimonials').find('.img.active');
  img.removeClass('active');
  if (img.is(':first-of-type')) {
    $('.testimonials').find('.img:last-of-type').addClass('active');
  } else {
    img.prev().addClass('active');
  }
});



$('.close-x').click(function() {
  $('.team-grid').removeClass('open');
  $('.team-member.open').removeClass('open');
});

$('.team-member').click(function(e) {
  var $tgt = $(e.target);
	if (!$tgt.hasClass('close-x')) {
		$(this).addClass('open');
    $('.team-grid').addClass('open');
  }

    $('html, body').animate(
    {
      scrollTop: $(this).offset().top,
    },
    500,
    'linear'
  )
});


$('.faq-list>li').click(function() {
  $(this).toggleClass('open');
})

$('.square-toggle').click(function() {
  var index = $(this).parents('.scroll-square').index();
  $(this).parents('.scroll-row').find('.scroll-square').addClass('active');
  $(this).parents('.scroll-row').addClass('active');
  $('.photo-slider').slick('slickGoTo', index);
  $('.photo-slider').slick('slickSetOption', 'slidesToShow', 1, true);
});

$('.close-square').click(function() {
  $('.scroll-row').removeClass('active');
  $('.scroll-square').removeClass('active');
  $('.photo-slider').slick('slickSetOption', 'slidesToShow', 3, true);
})


$('.media-toolkit').find('.header-toggle').click(function() {
  $('.media-toolkit').toggleClass('open');
});



function faqSearch() {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById('faq-search');
  filter = input.value.toUpperCase();
  ul = document.getElementById("faq-list");
  li = ul.getElementsByTagName('li');

  for (i = 0; i < li.length; i++) {
    if (input.value == '') {
      li[i].classList.remove('open');
      li[i].style.display = "";
    } else {
      txtValue = li[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
        li[i].classList.add("open");
      } else {
        li[i].style.display = "none";
        li[i].classList.remove("open");
      }
    }

      }
}

$('[data-copy-clipboard="full"]').on('click', function(e) {
  e.preventDefault();
  var $button = $(this);
  var el = $('.article-body')[0];
  copyToClipboard(el, $button);
});

$('[data-copy-clipboard="abstract"]').on('click', function(e) {
  e.preventDefault();
  var $button = $(this);
  var el = $('.article-summary')[0];
  copyToClipboard(el, $button);
});

function copyToClipboard(el, $button) {
  if(document.body.createTextRange) {
    var range = document.body.createTextRange();
    range.moveToElementText(el);
    range.select();
    document.execCommand("Copy");
    showToolTip($button);
  }
  else if(window.getSelection) {
    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("Copy");
    showToolTip($button);
  }
}

function showToolTip($el) {
  $el.addClass('active');
  setTimeout(function(){ 
    $el.removeClass('active');
  }, 1500);
}


var formData = {
  text: {
    full: {
      fileName: "fullText.txt",
      data: null
    },
    abstract: {
      fileName: "abstractText.txt",
      data: null
    }
  },
  html: {
    full: {
      fileName: "fullText.pdf",
      data: null,
      el: null
    },
    abstract: {
      fileName: "abstractText.pdf",
      data: null,
      el: null
    }
  },
  images: []
};

var formMessage;
var closable = true;

$('#download-form').on('submit', function(e) {
  e.preventDefault();

  $('.file-list').each(function() {
    if($(this).is(':checked')) {
      formData.images.push({url: $(this).data('imgsrc'), fileName: $(this).data('filename') })
    }
  });
  var $this = $(this);
  var $form = $this;
  var data = $form.serialize();
  formMessage = $form.parent().find('.form-message').html();

  if(!validateSelectableDownloads()) return;

  var $progressBar = $form.find('.progress-bar');
  $progressBar.addClass('running');

  closable = false;

  axios({
    method: 'post',
    url: '',
    data: data
  })
  .then(function(response) {
    var data = response.data;
    var success = data.success;

    if(success) {
      $form[0].reset();
      updateFormMessage($form, 'Thanks for subscribing!', true);
      resetSelectableDownloads('.select-downloads');
      downloadZipFile("media_download_toolkit.zip");
    } else {
      var detail = data.response.detail;
      updateFormMessage($form, detail, false);
      closable = true;
    }

    $progressBar.removeClass('running');
  })
  .catch(function(error) {
    updateFormMessage($form, 'There was an error. Please try again.', false);
    closable = true;
  });
});

$('[name="files-all"]').change(function() {
  if(this.checked) {
    $('.file-list').prop( "checked", true );
  } else {
    $('.file-list').prop( "checked", false );
  }
});

$('[name="article-full"]').change(function() {
  if(this.checked) {
    formData.text.full.data = $('.article-body').text().trim();
  } else {
    delete formData.text.full.data;
  }
});

$('[name="abstract-full"]').change(function() {
  if(this.checked) {
    formData.text.abstract.data = $('.article-summary').text().trim();
  } else {
    delete formData.text.abstract.data;
  }
});

$('[name="article-pdf"]').change(function() {
  if(this.checked) {
    formData.html.full.data = $('.article-body').html().trim();
    var cleanValue = $(".article-body").html();
    cleanValue = cleanValue.replace(/’/g, "&#39;");
    cleanValue = cleanValue.replace(/•/g, "-");
    cleanValue = cleanValue.replace(/…/g, "...");
    cleanValue = cleanValue.replace(/“/g, "&#34;");
    cleanValue = cleanValue.replace(/”/g, "&#34;");
    cleanValue = cleanValue.replace(/—/g, "-");
    cleanValue = cleanValue.replace(/–/g, "-");
    $(".article-body").html(cleanValue);
    formData.html.full.el = $('.article-body')[0];
  } else {
    delete formData.html.full.data;
  }
});

$('[name="abstract-pdf"]').change(function() {
  if(this.checked) {
    formData.html.abstract.data = $('.article-summary').html().trim();
    formData.html.abstract.el = $('.article-summary')[0]; 
  } else {
    delete formData.html.abstract.data;
  }
});

function validateSelectableDownloads() {
  var $formMessage = $('.select-downloads').find('.form-message');
  $formMessage.removeClass('error').empty();
  if(!formData.text.full.data && !formData.text.abstract.data && !formData.images.length && !formData.html.full.data && !formData.html.abstract.data) {
    $formMessage.addClass('error').html('Please select at least one of the items below');
    return false;
  }
  return true;
}

function updateFormMessage($form, message, success) {
  if(success) {
    $form.parent().find('.form-message').removeClass('error').html(message);
  }
  else {
    $form.parent().find('.form-message').addClass('error').html(message);
  }
}

function resetFormMessage(form, message) {
  $(form).parent().find('.form-message').removeClass('error').html(message);
}

function resetSelectableDownloads(el) {
  $(el).find('input[type="checkbox"]').each(function(i, e) {
    $(e).prop('checked', false);
  });
}

function resetFormData() {
  delete formData.text.full.data;
  delete formData.text.abstract.data;
  delete formData.html.full.data;
  delete formData.html.abstract.data;
  formData.images = [];
}

function getArticleImages() {
  var images = [];
  var $images = $('.article-content').find('img');
  $images.each(function(i, e) {
    var $image = $(e);
    var url = $image.attr('src');
    var fileName = url.split('/').pop();
    images.push({ url : url, fileName : fileName });
  });
  return images;
}

function getArticleFiles() {
  var files = [];
  var $files = $('.article-body').find('a');
  $files.each(function(i, e) {
    var $file = $(e);
    var url = $file.attr('href');
    var fileName = url.split('/').pop();
    var name = $file.text();

    if (checkURL(url)) {
      files.push({ url : url, fileName : fileName, name: name });
    }

      });
  return files;
}

function checkURL(url) {
  return(url.match(/\.(jpeg|jpg|gif|png|tiff|psd|eps|doc|docx|pages|pdf|mov|mp4|wmv|flv|avi|ppt|pptx|txt|xls|xlsx|xlsm|csv|xml|svg|ics)$/) != null);
}

function downloadZipFile(fileName) {
  var zip = new JSZip();
  Object.keys(formData.text).forEach(function(key,index) {
    var obj = formData.text[key];
    if(obj.data) {
      zip.file(obj.fileName, obj.data);
    }
  });

    Object.keys(formData.html).forEach(function(key,index) {
    var obj = formData.html[key];
    if(obj.data) {
      generatePDF(obj.fileName, obj.el, function(fileName, output) {
        zip.file(fileName, output, {binary: true});
      });
    }
  });

    if(formData.images.length) {

        var images = zip.folder("files");

    $.each(formData.images, function(i, e) {
      images.file(e.fileName, urlToPromise(e.url), {binary: true});
    });
  }
  zip.generateAsync({type:"blob"})
  .then(function(content) {
      window.saveAs(content, fileName);
      resetFormData();
      closable = true;
  });
}

function urlToPromise(url) {
  return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
          if(err) {
              reject(err);
          } else {
              resolve(data);
          }
      });
  });
}

function generatePDF(fileName, el, callback) {
  var doc = new jsPDF();
  doc.fromHTML(el, 10, 10, { width: 180 });
  var output = doc.output('blob');
  callback(fileName, output);
}

$(function() {
  var images = getArticleImages();

  images.forEach(function(e, index) {

    $('#files-download').append(
      '<div class="checkbox">' +
        '<input type="checkbox" class="file-list" data-filename="'+e.fileName+'" data-imgsrc="'+e.url+'" name="photo-' +index+ '" id="photo-' +index+ '" value="yes">' +
        '<label for="photo-' +index+ '"><i class="icon-check"></i> <span>Image: '+e.fileName+'</span></label>' +
      '</div>'
    )
  });

  var files = getArticleFiles();

  files.forEach(function(e, index) {

    $('#files-download').append(
      '<div class="checkbox">' +
        '<input type="checkbox" class="file-list" data-filename="'+e.fileName+'" data-imgsrc="'+e.url+'" name="file-' +index+ '" id="file-' +index+ '" value="yes">' +
        '<label for="file-' +index+ '"><i class="icon-check"></i> <span>'+e.name+': '+e.fileName+'</span></label>' +
      '</div>'
    )
  });
});

$(function() {
  $('.photo-slider').slick({
    slidesToShow: 3,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

var page = 2;

$('#loadMore').on('click', function(e) {
  e.preventDefault();
  var $button = $(this);
  $button.addClass('loading');
  $button.text('Loading...');
  var total = $button.data('total');
  $.get({
      type: 'get',
      url: '/newsroom/p'+page,
      success: function(data) {
          $button.removeClass('loading');
          $button.text('Load More');
          $('#news-grid-items').append(data);
          page ++;
          if(page >= total + 1) {
            $button.parent().remove();
          }
      },
      error: function(error) {
          console.log(error);
      }
  })
});

$('#loadMore-photos').on('click', function(e) {
  e.preventDefault();
  var $button = $(this);
  $button.addClass('loading');
  $button.text('Loading...');
  var total = $button.data('total');
  $.get({
      type: 'get',
      url: '/photos-videos/photos/p'+page,
      success: function(data) {
          $button.removeClass('loading');
          $button.text('Load More');
          $('#news-grid-items').append(data);
          page ++;
          if(page >= total + 1) {
            $button.parent().remove();
          }
      },
      error: function(error) {
          console.log(error);
      }
  })
});

$('#loadMore-videos').on('click', function(e) {
  e.preventDefault();
  var $button = $(this);
  $button.addClass('loading');
  $button.text('Loading...');
  var total = $button.data('total');
  $.get({
      type: 'get',
      url: '/photos-videos/videos/p'+page,
      success: function(data) {
          $button.removeClass('loading');
          $button.text('Load More');
          $('#news-grid-items').append(data);
          page ++;
          if(page >= total + 1) {
            $button.parent().remove();
          }
      },
      error: function(error) {
          console.log(error);
      }
  })
});

$valuesContent = $('.values-content');
$detailsContainer = $('.details-container');

$valuesContent.slick({
  slidesToShow: 1,
  fade: true,
  arrows: false,
  infinite: false,
  draggable: false
})

$detailsContainer.slick({
  slidesToShow: 1,
  fade: true,
  arrows : true,
  prevArrow: '<button type="button" class="slick-prev slick-arrow values-arrow"></button>',
  nextArrow: '<button type="button" class="slick-next slick-arrow values-arrow"></button>'
});

$detailsContainer.on('beforeChange', function(event, slick, currentSlide, nextSlide){
  $('.value').removeClass('active');
  $('.value').eq(nextSlide).addClass('active');
});

$('.value-trigger').click(function() {
  var index = $(this).index();
  $('.values-list').removeClass('active');
  $('.values-horizontal').addClass('active');
  $('.value').eq(index).addClass('active');
  $valuesContent.slick('slickGoTo', 1);
  $detailsContainer.addClass('active');
  $detailsContainer.slick('slickGoTo', index);
});

$('.value').click(function() {
  var index = $(this).index();
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
  $detailsContainer.slick('slickGoTo', index);
});

$('.values-arrow').click(function() {

});


function expandNotifications() {
  $('.form-shrink').slideDown();

  $('html, body').animate(
    {
      scrollTop: $('.form-shrink').offset().top,
    },
    500,
    'linear'
  )
}

$('.block-switcher').click(function() {
  $('#section-blocks').hide();
  $('#section-details').show();
  $('.block-switcher').removeClass('active');
  $('.details-wrapper').removeClass('active');


    var dataId = $(this).data('id');
  $('.block-switcher[data-id='+dataId+']').addClass('active');
  $('.details-wrapper[data-id='+dataId+']').addClass('active');
});

$('.reset-sections').click(function() {
  $('.block-switcher').removeClass('active');
  $('.details-wrapper').removeClass('active');
  $('#section-blocks').show();
  $('#section-details').hide();
});